import bindAll from 'lodash.bindall';
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import {
    createInitialProject,
    defaultProjectId,
    getIsFetchingWithId,
    getIsFetchingWithoutId,
    setProjectId
} from '../reducers/project-state';
import {getIsFetchingSession} from '../reducers/session';
import {getProjectIdFromHash} from './miscellaneous-utils';

/* Higher Order Component to get the project id from location.hash
 * @param {React.Component} WrappedComponent: component to render
 * @returns {React.Component} component with hash parsing behavior
 */
const HashParserHOC = function (WrappedComponent) {
    class HashParserComponent extends React.Component {
        constructor (props) {
            super(props);
            bindAll(this, [
                'handleLoadProject'
            ]);
        }
        componentDidMount () {
            // window.addEventListener('hashchange', () => {
            //     this.handleHashChange();
            // });
        }
        componentDidUpdate (prevProps) {
            if (!this.props.isFetchingWithId &&
                (this.props.projectIdFromRoute && (this.props.reduxProjectId !== this.props.projectIdFromRoute))){
                this.handleLoadProject();
            }

            if (this.props.isFetchingWithId && (this.props.reduxProjectId !== this.props.projectIdFromRoute)) {
                this.props.history.push(`/project/${this.props.reduxProjectId}`);
            }

            // if we are newly fetching a non-hash project...
            if (this.props.isFetchingWithoutId && !prevProps.isFetchingWithoutId) {
                // ...clear the hash from the url
                history.pushState('new-project', 'new-project',
                    window.location.pathname + window.location.search);
            }

            // only need to call handleHashChange after session is fetched
            // NB: session will load soon after GUI renders which results calling handleHashChange within notime
            if ((this.props.isFetchingSession !== prevProps.isFetchingSession) && !this.props.isFetchingSession){
                this.handleLoadProject();
            }
        }
        // componentWillUnmount () {
        //     window.removeEventListener('hashchange', this.handleHashChange);
        // }
        /** removing this function */
        // handleHashChange () {
        //     window.console.log('calling handleHashChange');
        //     // const projectIdFromHash = getProjectIdFromHash();
        //     // if (!projectIdFromHash && this.props.haveSessionUser){
        //     //     this.props.createInitialProject();
        //     // } else {
        //     //     const projectIdToLoad = projectIdFromHash || defaultProjectId;
        //     //     this.props.setProjectId(projectIdToLoad.toString());
        //     // }
        //     // const hashProjectId = hashMatch === null ? defaultProjectId : hashMatch[1];

        // }

        // repla
        handleLoadProject () {
            const {projectIdFromRoute} = this.props;
            if (!projectIdFromRoute && this.props.haveSessionUser){
                this.props.createInitialProject();
            } else {
                const projectIdToLoad = projectIdFromRoute || defaultProjectId;
                this.props.setProjectId(projectIdToLoad.toString());
            }
        }


        render () {
            const {
                /* eslint-disable no-unused-vars */
                isFetchingWithoutId: isFetchingWithoutIdProp,
                reduxProjectId,
                setProjectId: setProjectIdProp,
                /* eslint-enable no-unused-vars */
                ...componentProps
            } = this.props;
            return (
                <WrappedComponent
                    {...componentProps}
                />
            );
        }
    }
    HashParserComponent.propTypes = {
        isFetchingWithoutId: PropTypes.bool,
        isFetchingWithId: PropTypes.bool,
        isFetchingSession: PropTypes.bool,
        haveSessionUser: PropTypes.bool,
        reduxProjectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        setProjectId: PropTypes.func,
        // eslint-disable-next-line react/forbid-prop-types
        history: PropTypes.object,
        createInitialProject: PropTypes.func,
        projectIdFromRoute: PropTypes.string
    };
    const mapStateToProps = state => {
        const loadingState = state.scratchGui.projectState.loadingState;
        const haveSessionUser = !!(state.session && state.session.session && state.session.session.user);
        const sessionFetchingStatus = state.session.status;
        return {
            isFetchingWithoutId: getIsFetchingWithoutId(loadingState),
            isFetchingWithId: getIsFetchingWithId(loadingState),
            reduxProjectId: state.scratchGui.projectState.projectId,
            isFetchingSession: getIsFetchingSession(sessionFetchingStatus),
            haveSessionUser
        };
    };
    const mapDispatchToProps = dispatch => ({
        setProjectId: projectId => {
            dispatch(setProjectId(projectId));
        },
        createInitialProject: () => {
            dispatch(createInitialProject());
        }
    });
    // Allow incoming props to override redux-provided props. Used to mock in tests.
    const mergeProps = (stateProps, dispatchProps, ownProps) => Object.assign(
        {}, stateProps, dispatchProps, ownProps
    );
    return withRouter(connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(HashParserComponent));
};

export {
    HashParserHOC as default
};
