/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {getSessionCookies, removeSessionCookies, isLoginMandatory} from './session-utils';
import {setSessionUser} from '../reducers/session';
import {getUserBySession} from './qubits-api';

/*
 * Higher Order Component to provide extract session state. Creates a nested IntlProvider
 * to handle Gui intl context. The component accepts an onSetLanguage callback that is
 * called when the locale chagnes.
 * @param {React.Component} WrappedComponent - component to provide state for
 * @returns {React.Component} component with intl state provided from redux
 */
const SessionHOC = function (WrappedComponent) {
    class SessionWrapper extends React.Component {
        componentDidMount (){
            const [accessToken] = getSessionCookies();
            const isLoginMandatoryValue = isLoginMandatory();
            if (accessToken){
                getUserBySession().then(user => this.props.setSessionUser(user));
            } else if(isLoginMandatoryValue) {
                this.handleSignViaQubits();
            } else {
                this.props.setSessionUser(null);
            }
        }

        handleOnLogout (){
            removeSessionCookies();
            window.location.reload();
        }

        renderLogin ({onSignViaQubits}) {
            return (
                <div onClick={onSignViaQubits} >
                    <FormattedMessage
                        defaultMessage="Sign in via Qubits"
                        description="Link for signing in via Qubits"
                        id="gui.menuBar.signInViaQubits"
                    />
                </div>);
        }

        handleSignViaQubits () {
            window.location.href = `${
                process.env.REACT_APP_QUBITS_EDX_BASE_URL
            }oauth2/authorize?client_id=${
                process.env.REACT_APP_QUBITS_EDX_APP_CLIENT_ID
            }&scopr=email,profile&response_type=code`;
        }

        handleJoinQubits () {
            window.open(`${
                process.env.REACT_APP_QUBITS_EDX_BASE_URL
            }register`, '_blank').focus();
        }

        render () {
            const {
                ...componentProps
            } = this.props;
            return (
                <WrappedComponent
                    onLogOut={this.handleOnLogout}
                    renderLogin={this.renderLogin}
                    onSignViaQubits={this.handleSignViaQubits}
                    onOpenRegistration={this.handleJoinQubits}
                    {...componentProps}
                />
            );
        }
    }

    SessionWrapper.propTypes = {
        setSessionUser: PropTypes.func.isRequired
    };

    const mapDispatchToProps = dispatch => ({
        setSessionUser: user => dispatch(setSessionUser(user))
    });

    return connect(
        null,
        mapDispatchToProps
    )(SessionWrapper);
};

export default SessionHOC;
