const SET_PROJECT_INFO = 'preview/SET_PROJECT_INFO';
const SET_PROJECT_INFO_VISIBILIIY = 'preview/UPDATE_PROJECT_INFO_VISIBILTIY';

const initialState = {projectInfo: null};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case SET_PROJECT_INFO: {
        return Object.assign({}, state, {
            projectInfo: action.payload
        });
    }

    case SET_PROJECT_INFO_VISIBILIIY: {
        return ({...state, projectInfo: {...state.projectInfo, visibility_status: action.payload}});
    }

    default:
        return state;
    }
};

const setProjectInfo = projectInfo => ({
    type: SET_PROJECT_INFO,
    payload: projectInfo
});

const setProjectInfoVisibility = visibility => ({
    type: SET_PROJECT_INFO_VISIBILIIY,
    payload: visibility
});

export {
    reducer as default,
    initialState as previewInitialState,
    setProjectInfo,
    setProjectInfoVisibility
};
