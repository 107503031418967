import keyMirror from 'keymirror';

const SET_SESSION_USER = 'session/SET_SESSION_USER';

const LoadingState = keyMirror({
    FETCHED: null,
    FETCHING: null
});

const LoadingStates = Object.keys(LoadingState);

const getIsFetchingSession = sessionStatus => (
    sessionStatus === LoadingState.FETCHING
);

const initialState = {status: LoadingState.FETCHING, session: null};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case SET_SESSION_USER: {
        return Object.assign({}, state, {
            status: LoadingState.FETCHED,
            session: {
                user: action.payload
            }
        });
    }

    default:
        return state;
    }
};

const setSessionUser = user => ({
    type: SET_SESSION_USER,
    payload: user
});

export {
    reducer as default,
    initialState as sessionInitialState,
    setSessionUser,
    LoadingStates,
    getIsFetchingSession
};
