import xhr from 'xhr';
import {getSessionCookies} from './session-utils';

const getUserBySession = () => new Promise(resolve => {
    const [accessToken] = getSessionCookies();
    xhr({
        uri: `${process.env.REACT_APP_API_ENDPOINT}api/user`,
        headers: {authorization: `Bearer ${accessToken}`},
        json: true
    }, (error, response) => {
        let user = null;
        if (!(error || response.statusCode !== 200)) {
            user = response.body.data && response.body.data[0];
        }
        resolve(user);
    });
});

const createNewProject = () => new Promise(resolve => {
    const [accessToken] = getSessionCookies();
    xhr({
        method: 'POST',
        uri: `${process.env.REACT_APP_API_ENDPOINT}api/project/create-new`,
        headers: {authorization: `Bearer ${accessToken}`},
        json: true
    }, (error, response) => {
        let result;
        if (!(error || response.statusCode !== 200)) {
            result = response.body.data && response.body.data;
        }
        resolve(result);
    });
});

const createProjectSave = projectId => new Promise(resolve => {
    const [accessToken] = getSessionCookies();
    xhr({
        method: 'POST',
        uri: `${process.env.REACT_APP_API_ENDPOINT}api/project/${projectId}/create-save`,
        headers: {authorization: `Bearer ${accessToken}`},
        json: true
    }, (error, response) => {
        let result;
        if (!(error || response.statusCode !== 200)) {
            result = response.body.data && response.body.data;
        }
        resolve(result);
    });
});

const copyProject = projectId => new Promise(resolve => {
    const [accessToken] = getSessionCookies();
    xhr({
        method: 'POST',
        uri: `${process.env.REACT_APP_API_ENDPOINT}api/project/${projectId}/create-copy`,
        headers: {authorization: `Bearer ${accessToken}`},
        json: true
    }, (error, response) => {
        let result;
        if (!(error || response.statusCode !== 200)) {
            result = response.body.data && response.body.data;
        }
        resolve(result);
    });
});


const saveProjectToS3 = (postUrl, data) => new Promise(resolve => {
    xhr({
        method: 'POST',
        uri: postUrl,
        body: data
    }, (error, response) => {
        resolve(response);
    });
});

const getProject = projectId => new Promise((resolve,reject) => {
    const [accessToken] = getSessionCookies();
    const xhrOpts = {
        uri: `${process.env.REACT_APP_API_ENDPOINT}api/project/${projectId}`,
        json: true
    };
    if (accessToken) {
        xhrOpts.headers = {authorization: `Bearer ${accessToken}`};
    }
    xhr(xhrOpts, (error, response) => {
        let result;
        if (!(error || response.statusCode !== 200)) {
            result = response.body.data && response.body.data;
        }
        return result ? resolve(result) : reject(error);
    });
});

const getProjectLastSaveSignedUrl = projectId => new Promise(resolve => {
    const [accessToken] = getSessionCookies();
    const xhrOpts = {
        uri: `${process.env.REACT_APP_API_ENDPOINT}api/project/${projectId}/get-last-save-signed-url`,
        json: true
    };
    if (accessToken) {
        xhrOpts.headers = {authorization: `Bearer ${accessToken}`};
    }
    xhr(xhrOpts, (error, response) => {
        let result;
        if (!(error || response.statusCode !== 200)) {
            result = response.body.data && response.body.data;
        }
        resolve(result);
    });
});

const updateProjectVisibiltiy = (projectId, visibility) => new Promise(resolve => {
    const [accessToken] = getSessionCookies();
    const xhrOpts = {
        method: 'PATCH',
        uri: `${process.env.REACT_APP_API_ENDPOINT}api/project/${projectId}/visibility`,
        json: true,
        headers: {authorization: `Bearer ${accessToken}`},
        body: {
            visibility
        }
    };
    xhr(xhrOpts, (error, response) => {
        if (!(error || response.statusCode !== 200)) {
            resolve();
        }
    });
});

const updateProjectTitle = (projectId, title) => new Promise(resolve => {
    const [accessToken] = getSessionCookies();
    const xhrOpts = {
        method: 'PATCH',
        uri: `${process.env.REACT_APP_API_ENDPOINT}api/project/${projectId}/title`,
        json: true,
        headers: {authorization: `Bearer ${accessToken}`},
        body: {
            title
        }
    };
    xhr(xhrOpts, (error, response) => {
        if (!(error || response.statusCode !== 200)) {
            resolve();
        }
    });
});

const updateProjectThumbnail = (projectId, projectThumbnailBlob) => new Promise(resolve => {
    const [accessToken] = getSessionCookies();
    const xhrOpts = {
        method: 'GET',
        uri: `${process.env.REACT_APP_API_ENDPOINT}api/project/${projectId}/update-thumbnail-and-get-upload-signed-url`,
        json: true,
        headers: {authorization: `Bearer ${accessToken}`},
    };
    xhr(xhrOpts, (error, response) => {
        if (!(error || response.statusCode !== 200)) {
            // resolve();
            const result = response.body.data && response.body.data;
            const formData = new FormData();
            Object.keys(result.fields).forEach(key => {
                formData.append(key, result.fields[key]);
            });
            formData.append('file', projectThumbnailBlob);
            xhr({
                method: 'POST',
                uri: result.url,
                body: formData
            }, () => {
                resolve();
            });
        }
    });
});


export {
    getUserBySession,
    createNewProject,
    getProject,
    updateProjectVisibiltiy,
    createProjectSave,
    getProjectLastSaveSignedUrl,
    saveProjectToS3,
    updateProjectTitle,
    copyProject,
    updateProjectThumbnail
};
