import classNames from 'classnames';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import React from 'react';
import bindAll from 'lodash.bindall';
import styles from './qubits-share.css';
import {setProjectInfoVisibility} from '../../reducers/preview';
import Button from '../button/button.jsx';
import copyIcon from './copy.png';
import {setPlayer} from '../..';

class QubitsShare extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleShare',
            'handleClickCopy',
            'handleSeeInside'
        ]);
        this.state = {
            projectVisibiltyStatus: props.projectVisibiltyStatus
        };
    }

    handleShare (ev) {
        this.setState({projectVisibiltyStatus: ev.target.value});
        this.props.onShareStatusChange(ev.target.value);
        this.props.onShare(this.props.projectId, ev.target.value).then(() => {
            this.setState({shareStatusChangeProgressing: false});
        });
    }

    handleSeeInside () {
        this.props.history.push(`/project/${this.props.projectId}`);
    }

    handleClickCopy () {
        const copyText = document.getElementById('share-url-input');
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
    }

    render () {
        const {isUserProjectOwner} = this.props;
        const {projectVisibiltyStatus} = this.state;
        return (
            <div className={classNames(styles.wrapper)}>
                <Button
                    onClick={this.handleSeeInside}
                    className={classNames(styles.seeInsideBtn)}
                >{'See inside'}</Button>
                {isUserProjectOwner && (<React.Fragment>
                    <div className={classNames(styles.shareContainer)}>
                        <span className={classNames(styles.shareLabel)} >{'Share: '}</span>
                        <select
                            className={classNames(styles.visibilitySelect)}
                            onChange={this.handleShare}
                        >
                            <option
                                selected={projectVisibiltyStatus === 'private'}
                                value="private"
                            >{'Private'}</option>
                            <option
                                selected={projectVisibiltyStatus === 'teacher'}
                                value="teacher"
                            >{'Teachers'}</option>
                            <option
                                selected={projectVisibiltyStatus === 'student'}
                                value="student"
                            >{'Students'}</option>
                            <option
                                selected={projectVisibiltyStatus === 'public'}
                                value="public"
                            >{'Public'}</option>
                        </select>
                    </div>

                    {projectVisibiltyStatus === 'public' && (
                        <div className={classNames(styles.banner, styles.sharedBanner)}>
                            <span className={classNames(styles.shareText)}>
                                {'Your project is shared publicly, everyone can access it.'}
                            </span>
                        </div>)}
                    {projectVisibiltyStatus === 'student' && (
                        <div className={classNames(styles.banner, styles.sharedBanner)}>
                            <span className={classNames(styles.shareText)}>
                                {'Your project is shared to students, all students can access it.'}
                            </span>
                        </div>)}
                    {projectVisibiltyStatus === 'teacher' && (
                        <div className={classNames(styles.banner, styles.sharedBanner)}>
                            <span className={classNames(styles.shareText)}>
                                {'Your project is shared to teachers, all teachers can access it.'}
                            </span>
                        </div>)}
                    {projectVisibiltyStatus === 'private' ? (
                        <React.Fragment>
                            <div className={classNames(styles.banner, styles.unSharedBanner)}>
                                <span className={classNames(styles.shareText)}>
                                    {'Your project is private, no one can access it.'}
                                </span>
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <span className={classNames(styles.copyShareLabel)}>{'Copy / Share project Url:'}</span>
                            <div
                                onClick={this.handleClickCopy}
                                className={classNames(styles.shareUrlInputWrapper)}
                            >
                                <input
                                    id="share-url-input"
                                    type="text"
                                    readOnly
                                    className={classNames(styles.shareUrlInputText)}
                                    value={`${process.env.REACT_APP_EDITOR_BASE_PATH}#/project/${this.props.projectId}/player`}
                                />
                                <img
                                    className={classNames(styles.copyIconImage)}
                                    src={copyIcon}
                                />
                            </div>
                        </React.Fragment>
                    )}
                </React.Fragment>)}
            </div>
        );

    }
}

QubitsShare.propTypes = {
    isUserProjectOwner: PropTypes.bool.isRequired,
    onShare: PropTypes.func.isRequired,
    onShareStatusChange: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
    projectVisibiltyStatus: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    history: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    const user =
        state.session && state.session.session && state.session.session.user;
    const projectInfo =
        state.preview && state.preview.projectInfo && state.preview.projectInfo;
    const isUserProjectOwner =
        projectInfo && user && user.id === projectInfo.created_by;
    const projectVisibiltyStatus = projectInfo && projectInfo.visibility_status;
    return {
        isUserProjectOwner,
        projectId: state.scratchGui.projectState.projectId,
        projectVisibiltyStatus
    };
};

const mapDispatchToProps = dispatch => ({
    onShareStatusChange: updatedVisibility => dispatch(setProjectInfoVisibility(updatedVisibility))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QubitsShare));
