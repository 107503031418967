import classNames from 'classnames';
import PropTypes from 'prop-types';
import bindAll from 'lodash.bindall';
import React from 'react';

import styles from './share-button.css';
/** Overided class */
class ShareButton extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, ['handleVisibilityChange']);
    }

    handleVisibilityChange (ev){
        this.props.onClick(ev.target.value);
    }

    render () {

        return (
            <div className={classNames(styles.shareContainer)}>
                <span className={classNames(styles.shareLabel)} >{'Share: '}</span>
                <select
                    className={classNames(styles.visibilitySelect)}
                    onChange={this.handleVisibilityChange}
                >
                    <option
                        selected={this.props.visibilityStatus === 'private'}
                        value="private"
                    >{'Private'}</option>
                    <option
                        selected={this.props.visibilityStatus === 'teacher'}
                        value="teacher"
                    >{'Teachers'}</option>
                    <option
                        selected={this.props.visibilityStatus === 'student'}
                        value="student"
                    >{'Students'}</option>
                    <option
                        selected={this.props.visibilityStatus === 'public'}
                        value="public"
                    >{'Public'}</option>
                </select>
            </div>
        );
    }
}

ShareButton.propTypes = {
    onClick: PropTypes.func,
    visibilityStatus: PropTypes.string
};

ShareButton.defaultProps = {
    onClick: () => {}
};


export default ShareButton;
