exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .share-button_share-container_1bUbf {\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    min-width: 158px;\n    -webkit-box-pack: center;\n    -webkit-justify-content: center;\n        -ms-flex-pack: center;\n            justify-content: center;\n    -webkit-box-align: stretch;\n    -webkit-align-items: stretch;\n        -ms-flex-align: stretch;\n            align-items: stretch;\n    height: 34px;\n} .share-button_share-label_MpNn1 {\n    margin-right: 4px;\n    -webkit-align-self: center;\n        -ms-flex-item-align: center;\n            align-self: center;\n} .share-button_visibility-select_36AzY {\n    margin-right: 10px;\n} .share-button_share-button_Nxxf0 {\n    background: hsla(30, 100%, 55%, 1);\n} .share-button_share-button-is-shared_1tjKq {\n    background: hsla(0, 0%, 0%, 0.15);\n    cursor: default;\n} .share-button_share-button-is-disabled_3JN4r {\n    background: hsla(0, 0%, 0%, 0.15);\n    cursor: default;\n}\n", ""]);

// exports
exports.locals = {
	"share-container": "share-button_share-container_1bUbf",
	"shareContainer": "share-button_share-container_1bUbf",
	"share-label": "share-button_share-label_MpNn1",
	"shareLabel": "share-button_share-label_MpNn1",
	"visibility-select": "share-button_visibility-select_36AzY",
	"visibilitySelect": "share-button_visibility-select_36AzY",
	"share-button": "share-button_share-button_Nxxf0",
	"shareButton": "share-button_share-button_Nxxf0",
	"share-button-is-shared": "share-button_share-button-is-shared_1tjKq",
	"shareButtonIsShared": "share-button_share-button-is-shared_1tjKq",
	"share-button-is-disabled": "share-button_share-button-is-disabled_3JN4r",
	"shareButtonIsDisabled": "share-button_share-button-is-disabled_3JN4r"
};